var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"ma-1 pa-1"},[_c('v-card-title',{staticClass:"pa-1"},[_c('span',[_vm._v(_vm._s(_vm.$t('message.report')))]),_c('v-spacer'),_c('v-text-field',{staticClass:"mr-2",staticStyle:{"width":"100px !important"},attrs:{"append-icon":"mdi-magnify","placeholder":_vm.$t('search'),"outlined":"","dense":"","single-line":"","hide-details":""},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getList.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"ma-1",staticStyle:{"border":"1px solid #aaa"},attrs:{"dense":"","fixed-header":"","loading-text":_vm.$t('loadingText'),"no-data-text":_vm.$t('noDataText'),"height":_vm.screenHeight,"loading":_vm.loading,"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"item-key":"id","multi-sort":"","footer-props":{
        itemsPerPageOptions: [ 50, 100, 200, -1 ],
        itemsPerPageAllText:_vm.$t('itemsPerPageAllText'),
        itemsPerPageText: _vm.$t('itemsPerPageText'),
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-arrow-left',
        nextIcon: 'mdi-arrow-right'
      },"expanded":_vm.expanded,"single-expand":"","show-expand":""},on:{"update:expanded":[function($event){_vm.expanded=$event},_vm.toggleExpand],"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
      var item = ref.item;
return [_vm._v(_vm._s(_vm.items.map(function(x) {return x.id; }).indexOf(item.id) + 1))]}},{key:"item.create_document",fn:function(ref){
      var item = ref.item;
return [(item.create_documents.length)?_c('v-menu',{attrs:{"bottom":"","origin":"center center","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"","block":"","text":"","small":""}},'v-btn',attrs,false),on),[_vm._v(_vm._s(item.create_document))])]}}],null,true)},[_c('v-list',{staticClass:"pa-1"},_vm._l((item.create_documents),function(item,i){return _c('p',{key:i,staticClass:"ma-1 text-center"},[_c('v-btn',{attrs:{"color":"primary","block":"","outlined":"","text":"","small":"","target":"_blank","to":'show/'+item.document_id}},[_vm._v(_vm._s(item.document_number))])],1)}),0)],1):_vm._e()]}},{key:"item.expired",fn:function(ref){
      var item = ref.item;
return [(item.expired_documents.length)?_c('v-menu',{attrs:{"bottom":"","origin":"center center","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","block":"","text":"","small":""}},'v-btn',attrs,false),on),[_vm._v(_vm._s(item.expired))])]}}],null,true)},[_c('v-list',{staticClass:"pa-1"},_vm._l((item.expired_documents),function(item,i){return _c('p',{key:i,staticClass:"ma-1 text-center"},[_c('v-btn',{attrs:{"color":"error","block":"","outlined":"","text":"","small":"","target":"_blank","to":'show/'+item.document_id}},[_vm._v(_vm._s(item.document_number))])],1)}),0)],1):_vm._e()]}},{key:"item.prosesing",fn:function(ref){
      var item = ref.item;
return [(item.prosesing_documents.length)?_c('v-menu',{attrs:{"bottom":"","origin":"center center","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"","block":"","text":"","small":""}},'v-btn',attrs,false),on),[_vm._v(_vm._s(item.prosesing))])]}}],null,true)},[_c('v-list',{staticClass:"pa-1"},_vm._l((item.prosesing_documents),function(item,i){return _c('p',{key:i,staticClass:"ma-1 text-center"},[_c('v-btn',{attrs:{"color":"primary","block":"","outlined":"","text":"","small":"","target":"_blank","to":'show/'+item.document_id}},[_vm._v(_vm._s(item.document_number))])],1)}),0)],1):_vm._e()]}},{key:"item.waiting",fn:function(ref){
      var item = ref.item;
return [(item.waiting_documents.length)?_c('v-menu',{attrs:{"bottom":"","origin":"center center","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"","block":"","text":"","small":""}},'v-btn',attrs,false),on),[_vm._v(_vm._s(item.waiting))])]}}],null,true)},[_c('v-list',{staticClass:"pa-1"},_vm._l((item.waiting_documents),function(item,i){return _c('p',{key:i,staticClass:"ma-1 text-center"},[_c('v-btn',{attrs:{"color":"primary","block":"","outlined":"","text":"","small":"","target":"_blank","to":'show/'+item.document_id}},[_vm._v(_vm._s(item.document_number))])],1)}),0)],1):_vm._e()]}},{key:"item.draft",fn:function(ref){
      var item = ref.item;
return [(item.draft_documents.length)?_c('v-btn',{attrs:{"color":"","block":"","text":"","small":""}},[_vm._v(_vm._s(item.draft))]):_vm._e()]}},{key:"item.completed_on_time",fn:function(ref){
      var item = ref.item;
return [(item.completed_on_time_documents.length)?_c('v-btn',{attrs:{"color":"","block":"","text":"","small":""}},[_vm._v(_vm._s(item.completed_on_time))]):_vm._e()]}},{key:"item.failed_in_time",fn:function(ref){
      var item = ref.item;
return [(item.failed_in_time_documents.length)?_c('v-btn',{attrs:{"color":"","block":"","text":"","small":""}},[_vm._v(_vm._s(item.failed_in_time))]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
      var headers = ref.headers;
      var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{staticClass:"justify-center"},[_c('v-col',{staticClass:"py-1 ma-2",attrs:{"cols":"12","md":"10","lg":"9","xl":"7"}},[_c('v-card',{staticClass:"pa-3"},[_c('strong',[_vm._v(_vm._s(_vm.$t("department.index"))+":")]),_vm._v(" "+_vm._s(item.department)+" "),_c('br'),_c('strong',[_vm._v(_vm._s(_vm.$t("position.index"))+":")]),_vm._v(" "+_vm._s(item.position)+" ")])],1)],1)],1)]}}])})],1),_c('v-dialog',{attrs:{"width":"300","hide-overlay":""},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t('loadingText'))+" "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }