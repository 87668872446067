<template>
  <div>
    <v-card class="ma-1 pa-1">
      <v-card-title class="pa-1">
        <span>{{ $t('message.report') }}</span>
        <v-spacer></v-spacer>

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          class="mr-2"
          style="width:100px !important;"
          :placeholder="$t('search')"
          @keyup.native.enter="getList"
          outlined
          dense
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        dense
        fixed-header
        :loading-text="$t('loadingText')"
        :no-data-text="$t('noDataText')"
        :height="screenHeight"
        :loading="loading"
        :headers="headers"
        :items="items"
        :search="search"
        class="ma-1"
        style="border: 1px solid #aaa;"
        item-key="id"
        multi-sort
        :footer-props="{
          itemsPerPageOptions: [ 50, 100, 200, -1 ],
          itemsPerPageAllText:$t('itemsPerPageAllText'),
          itemsPerPageText: $t('itemsPerPageText'),
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-arrow-left',
          nextIcon: 'mdi-arrow-right'
        }"
        :expanded.sync="expanded"
        single-expand
        show-expand
        @update:expanded="toggleExpand"
        @click:row="rowClick"
      >
        <template
          v-slot:item.id="{ item }"
        >{{items.map(function(x) {return x.id; }).indexOf(item.id) + 1}}</template>
        <template v-slot:item.create_document="{ item }">
          <v-menu
            v-if="item.create_documents.length"
            bottom
            origin="center center"
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn color block text small v-bind="attrs" v-on="on">{{ item.create_document }}</v-btn>
            </template>

            <v-list class="pa-1">
              <p v-for="(item, i) in item.create_documents" :key="i" class="ma-1 text-center">
                <v-btn
                  color="primary"
                  block
                  outlined
                  text
                  small
                  target="_blank"
                  :to="'show/'+item.document_id"
                >{{ item.document_number }}</v-btn>
              </p>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:item.expired="{ item }">
          <v-menu
            v-if="item.expired_documents.length"
            bottom
            origin="center center"
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="error" block text small v-bind="attrs" v-on="on">{{ item.expired }}</v-btn>
            </template>

            <v-list class="pa-1">
              <p v-for="(item, i) in item.expired_documents" :key="i" class="ma-1 text-center">
                <v-btn
                  color="error"
                  block
                  outlined
                  text
                  small
                  target="_blank"
                  :to="'show/'+item.document_id"
                >{{ item.document_number }}</v-btn>
              </p>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:item.prosesing="{ item }">
          <v-menu
            v-if="item.prosesing_documents.length"
            bottom
            origin="center center"
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn color block text small v-bind="attrs" v-on="on">{{ item.prosesing }}</v-btn>
            </template>

            <v-list class="pa-1">
              <p v-for="(item, i) in item.prosesing_documents" :key="i" class="ma-1 text-center">
                <v-btn
                  color="primary"
                  block
                  outlined
                  text
                  small
                  target="_blank"
                  :to="'show/'+item.document_id"
                >{{ item.document_number }}</v-btn>
              </p>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:item.waiting="{ item }">
          <v-menu
            v-if="item.waiting_documents.length"
            bottom
            origin="center center"
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn color block text small v-bind="attrs" v-on="on">{{ item.waiting }}</v-btn>
            </template>

            <v-list class="pa-1">
              <p v-for="(item, i) in item.waiting_documents" :key="i" class="ma-1 text-center">
                <v-btn
                  color="primary"
                  block
                  outlined
                  text
                  small
                  target="_blank"
                  :to="'show/'+item.document_id"
                >{{ item.document_number }}</v-btn>
              </p>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:item.draft="{ item }">
          <v-btn v-if="item.draft_documents.length" color block text small>{{ item.draft }}</v-btn>
        </template>
        <template v-slot:item.completed_on_time="{ item }">
          <v-btn
            v-if="item.completed_on_time_documents.length"
            color
            block
            text
            small
          >{{ item.completed_on_time }}</v-btn>
        </template>
        <template v-slot:item.failed_in_time="{ item }">
          <v-btn
            v-if="item.failed_in_time_documents.length"
            color
            block
            text
            small
          >{{ item.failed_in_time }}</v-btn>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-row class="justify-center">
              <v-col cols="12" md="10" lg="9" xl="7" class="py-1 ma-2">
                <v-card class="pa-3">
                  <strong>{{ $t("department.index") }}:</strong>
                  {{ item.department }}
                  <br />
                  <strong>{{$t("position.index")}}:</strong>
                  {{ item.position }}
                </v-card>
              </v-col>
            </v-row>
          </td>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="loading" width="300" hide-overlay>
      <v-card color="primary" dark>
        <v-card-text>
          {{ $t('loadingText') }}
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
const axios = require("axios").default;
import Swal from "sweetalert2";
export default {
  data() {
    return {
      loading: false,
      search: "",
      items: [],
      dataTableOptions: { page: 1, itemsPerPage: 50 },
      expanded: []
    };
  },
  computed: {
    screenHeight() {
      return window.innerHeight - 170;
    },
    language() {
      return this.$i18n.locale == "ru" ? "uz_cyril" : this.$i18n.locale;
    },
    headers() {
      return [
        { text: "", value: "data-table-expand" },
        { text: "#", value: "id", align: "center", width: 30 },
        {
          text: this.$t("employee.tabel"),
          value: "tabel",
          align: "center",
          width: 30
        },
        { text: this.$t("employee.info"), value: "employee_name" },
        { text: this.$t("report.create_document"), value: "create_document" },
        { text: this.$t("report.expired"), value: "expired" },
        { text: this.$t("report.prosesing"), value: "prosesing" },
        { text: this.$t("report.waiting"), value: "waiting" },
        { text: this.$t("document.drafts"), value: "draft" },
        {
          text: this.$t("report.completed_on_time"),
          value: "completed_on_time"
        },
        { text: this.$t("report.failed_in_time"), value: "failed_in_time" }
      ];
    }
  },
  methods: {
    toggleExpand($event) {},
    rowClick(item, row) {
      row.expand(!row.isExpanded);
      // if (this.expanded[0] && this.expanded[0].id == item.id) this.expanded = [];
      // else this.expanded = [item];
    },
    getList() {
      this.loading = true;
      axios
        .post(this.$store.state.backend_url + "api/documents/report", {
          pagination: this.dataTableOptions,
          search: this.search
        })
        .then(response => {
          this.items = response.data;
          this.items.map(v => {
            v.employee_name =
              v.employee["firstname_" + this.language] +
              " " +
              v.employee["lastname_" + this.language] +
              " " +
              v.employee["middlename_" + this.language];
            v.tabel = v.employee.tabel;
            v.department = v.staff.department["name_" + this.$i18n.locale];
            v.position = v.staff.position["name_" + this.$i18n.locale];
          });
          this.items = this.items.slice().sort(function(a, b) {
            return b.expired - a.expired;
          });
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
        });
    }
  },
  mounted() {
    this.getList();
  },
  created() {}
};
</script>

<style scoped>
table tbody tr td {
  white-space: normal !important;
}
</style>
